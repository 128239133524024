import * as React from 'react';
import { Helmet } from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import NewsList from '../components/NewsList';
import CareersCTA from '../components/CareersCTA';

import HeroVideo from '../images/home-hero.mp4';

import '../styles/homePage.less';

const HomePage = (): JSX.Element => {
    return (
        <>
            <Helmet title="Leader in developing and managing live video chat platforms" />
            <Layout classname="home">
                <div className="page page__lg">
                    <div className="hero hero--primary">
                        <video className="video-player" height="100%" width="100%" loop={true} muted={true} autoPlay={true}>
                            <source src={HeroVideo} type="video/mp4" />
                        </video>
                        <div className="hero__wrp">
                            <h1 className="hero__title">CC Media Network Ltd is a leader in developing and managing live video chat platforms and pay-as-you-go billing technologies in the entertainment sector.</h1>
                        </div>
                    </div>
                    <div className="page__cnt">
                        <div className="about-company">
                            <h2>Since 1999</h2>
                            <p>Based in Gibraltar, we’ve been a pioneer in the industry since 1999. We’ve built relationships with hundreds of thousands of users and content partners and we manage millions of minutes of live video chat every year.</p>
                            <p>Our network of users accessing our platforms are truly global, and we provide them with the best customer service in the industry.</p>
                        </div>
                    </div>
                    <div className="facts">
                        <ul className="facts__cnt">
                            <li className="facts__item">
                                <h3 className="page__subtitle">No app or download needed</h3>
                                <p>Our proprietary live video chat platforms work with two-way video across all browsers, bandwidths, and firewalls without needing to download an app or any other software.</p>
                            </li>
                            <li className="facts__item">
                                <h3 className="page__subtitle">Highly accessible and scalable.</h3>
                                <p>Our technology is entirely cloud-based and it’s highly scalable.</p>
                            </li>
                            <li className="facts__item">
                                <h3 className="page__subtitle">Fully optimized and responsive products</h3>
                                <p>Our platforms are fully optimized for all devices and all operating systems, whether desktop-based or mobile.</p>
                            </li>
                        </ul>
                    </div>
                    <div className="hero hero--secondary">
                        <div className="hero__wrp">
                            <p>
                                <b>CC Media Network stays true to its motto…</b> ANY PLACE, ANY TIME, ON ANY DEVICE.
                            </p>
                        </div>
                        <StaticImage src="../images/bg-home-hero-secondary.png" alt="Any place, any time, on any device" />
                    </div>
                    <div className="page__cnt">
                        <div className="points">
                            <h4 className="points__title">We’ve been innovators in live video chat since the beginning:</h4>
                            <ul className="points__list">
                                <li className="points__item">In the vanguard of proprietary live video streaming technology</li>
                                <li className="points__item">The first to introduce interactive video entertainment on mobile devices</li>
                                <li className="points__item">The first to develop software allowing video streaming from mobile devices</li>
                                <li className="points__item">The first to introduce truly optimized apps across iOS and Android devices</li>
                                <li className="points__item">The first to launch live video delivery from Android devices with full two-way high-definition video, audio and text-chat capability</li>
                            </ul>
                        </div>
                    </div>
                    <NewsList title="Company news" hasCta={true} />
                    <CareersCTA />
                </div>
            </Layout>
        </>
    );
};

export default HomePage;
